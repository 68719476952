import React from "react";
import PublicFooterMenu from "./PublicFooterMenu";
import PublicFooterWrapper from "./PublicFooterWrapper";
import PublicFooterTop from "./PublicFooterTop";
import PublicFooterSocialMedia from "./PublicFooterSocialMedia";
import PublicFooterBottom from "./PublicFooterBottom";
import PublicFooterNewsletter from "./PublicFooterNewsLetter";

const PublicFooter = () => {
  return (
    <PublicFooterWrapper>
      <div className="xl:flex xl:justify-between xl:border-b xl:border-gray-600 xl:mb-10 xl:pb-8">
        <div className="border-b border-gray-600 mb-10 pb-8 xl:border-none xl:mb-0 xl:pb-0">
          <PublicFooterTop />
        </div>

        <div className="  border-b border-gray-600 mb-10 pb-8 xl:border-none xl:mb-0 xl:pb-0">
          <PublicFooterMenu />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row-reverse gap-10 lg:justify-between  ">
        <PublicFooterNewsletter />

        <div className=" flex  flex-col lg:justify-between gap-6">
          {/* <PublicFooterSocialMedia /> */}
          <PublicFooterBottom />
        </div>
      </div>
    </PublicFooterWrapper>
  );
};

export default PublicFooter;
