import Link from "next/link";
import SideBarLinkComponent from "./LinkItem";
import { publicNavigation } from "@constants/navigation/navigation";

const PublicMobileMenuItems = ({ setMobileMenuOpen }) => (
  <ul
    role="list"
    className="  flex flex-col gap-y-2 -mx-3  "
    onClick={() => setMobileMenuOpen(false)}
  >
    {publicNavigation.map((item) => (
      <li className="" key={item.label}>
        <div className="mb-1 px-3 py-1 font-semibold text-sm  ">
          {item.href ? (
            <Link href={item.href}>
              <span>{item.label}</span>
            </Link>
          ) : (
            <span className="cursor-default">{item.label}</span>
          )}
        </div>

        <div>
          {item?.subitems?.map((subitem) => (
            <SideBarLinkComponent
              href={subitem.href}
              label={subitem.label}
              key={subitem.label}
            />
          ))}
        </div>
      </li>
    ))}
  </ul>
);

export default PublicMobileMenuItems;
