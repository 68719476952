"use client";

import React, { useEffect } from "react";

import { useForm, SubmitHandler } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useToast } from "@/components/ui/use-toast";
import { Form, FormField } from "@/components/ui/form";

import CustomInput from "@components/Form/CustomInput";
import CustomButton from "@components/CustomUi/CustomButton";
import { onSubmit } from "./onSubmit";

// Schema for form validation
const formSchema = z.object({
  email: z.string().min(1, "Email is required").email("Invalid email address"),
});

type FormSchema = z.infer<typeof formSchema>;

const Newsletter = () => {
  const { toast } = useToast();

  const defaultValues = {
    email: "",
  };

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: "onSubmit",
  });

  const mutation = useMutation({
    mutationFn: async (data: any) => {
      return await onSubmit({
        email: data?.email,
      });
    },
    onSuccess: (responseData) => {
      toast({
        title: responseData?.title,
        description: responseData?.msg,
      });
      form.reset();
    },
    onError: (error: Error) => {
      console.error(error);
      toast({
        title: "Error",
        description: error.message || "Something went wrong",
      });
    },
  });

  return (
    <div>
      {/* <p className="text-white text-xl font-semibold text-center mb-4">
        Get our newsletter
      </p> */}

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((data: any) => {
            mutation.mutate(data);
          })}
        >
          <div className="flex gap-x-2 items-center justify-between">
            <div className="w-full ">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <CustomInput
                    field={field}
                    placeholder="Email for newsletter"
                    className="text-black border-2 focus:border-main mt-0"
                    showError={false}
                  />
                )}
              />
            </div>

            <div className="mt-2">
              <CustomButton
                buttonText="Submit"
                isLoading={mutation.isPending}
              />
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default Newsletter;
