export async function onSubmit({ email }: { email: any }) {
  const sheetEndpoint = "/api/v1/newsletter/subscribe";

  const response = await fetch(sheetEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const responseData = await response.json();

  return responseData;
}
