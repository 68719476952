"use client";

import PublicHeader from "@components/Header/PublicHeader";
import PublicFooter from "@components/Footer/PublicFooter";
import { useState } from "react";
import PublicMobileMenu from "@components/Sidebar/PublicMobileMenu";
import { cn } from "@lib/utils";
import { usePathname } from "next/navigation";

import PublicContentWrapper from "@components/Public/PublicContentWrapper";

// https://hivebrite.io/?utm_term=online%20community%20saas&utm_source=google&utm_medium=cpc&utm_campaign=google_longtailkeywords__row_N_A__search_cpc&hsa_acc=6212665654&hsa_cam=20407337192&hsa_grp=147035023130&hsa_ad=667291301254&hsa_src=g&hsa_tgt=kwd-451209014867&hsa_kw=online%20community%20saas&hsa_mt=b&hsa_net=adwords&hsa_ver=3&gad_source=1&gclid=CjwKCAiAq4KuBhA6EiwArMAw1MJ74YAdf9Jg9XmZgcCdOwYpYORqHN7PU1i6UQM1mnNg--u_RS_juRoCiCIQAvD_BwE

export default function RootMarketingLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const isHomePage = usePathname() === "/";

  return (
    <div
      className={cn(
        "flex flex-col  bg-slate-100  ",
        isHomePage ? "" : "min-h-screen"
      )}
    >
      <PublicHeader
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />
      <PublicMobileMenu
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
      />
      <PublicContentWrapper>{children}</PublicContentWrapper>
      <PublicFooter />
    </div>
  );
}
