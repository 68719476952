export default function PublicContentWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    // <div className="flex-grow overflow-auto mb-14 md:mb-20">
    <div className="flex-grow overflow-auto mb-14 md:mb-20 ">
      <main className="mx-auto">{children}</main>
    </div>
  );
}
