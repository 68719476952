"use client";

import * as React from "react";
import { cn } from "@/lib/utils";
import { NavigationMenuLink } from "@/components/ui/navigation-menu";

type ListItemProps = {
  href: string;
  label: string;
  tagline: string;
  icon: React.ComponentType<React.ComponentProps<"svg">>;
};

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a"> & ListItemProps
>(({ className, label, tagline, icon: Icon, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="flex items-center  ">
            <div className="w-1/6 flex justify-start">
              <Icon className="h-8 w-8 bg-main rounded-full p-1.5 text-white" />
            </div>

            <div className="w-5/6">
              <div className="text-sm font-semibold mb-1">{label}</div>
              <div className="text-xs">{tagline}</div>
            </div>
          </div>
        </a>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = "ListItem";

export default ListItem;
