import React from "react";
import Link from "next/link";
import { Button } from "@components/ui/button";

const PublicFooterMenuSegment = ({
  label,
  subitems,
}: {
  label: string;
  subitems: { label: string; href: string }[];
}) => {
  return (
    <div className="">
      <h4 className="text-lg   mb-2 sm:mb-5 font-semibold">{label}</h4>
      <ul>
        {subitems.map((subitem) => (
          <li key={subitem.label} className="mb-2">
            <Link href={subitem.href}>
              <Button
                variant="link"
                size="link"
                className="text-sm    text-white capitalize"
              >
                {subitem.label}
              </Button>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PublicFooterMenuSegment;
