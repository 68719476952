"use client";
import { signIn } from "next-auth/react";
import { Button } from "@components/ui/button";

const SignInButton = ({
  label,
  variant,
  size,
  className,
}: {
  label: string;
  variant?:
    | "default"
    | "secondary"
    | "link"
    | "destructive"
    | "outline"
    | "ghost";
  size?: "default" | "sm" | "lg" | "icon";
  className?: string;
}) => {
  const handleSignIn = () => {
    signIn(undefined, { callbackUrl: "/loading" });
  };

  return (
    <Button
      onClick={handleSignIn}
      variant={variant || "default"}
      size={size || "default"}
      className={className}
    >
      <p className="capitalize">{label}</p>
    </Button>
  );
};

export default SignInButton;
