import { HamburgerMenuIcon, Cross1Icon } from "@radix-ui/react-icons";
import { SetMobileMenuOpenProps } from "@interfaces/general";
import { cn } from "@lib/utils";

export const HamburgerIcon = ({
  setMobileMenuOpen,
  classNames,
}: SetMobileMenuOpenProps) => {
  return (
    <div
      className={cn(
        "w-6 sm:h-6 md:w-7 md:h-7 ",
        "cursor-pointer flex justify-center items-center",
        classNames
      )}
      onClick={() => setMobileMenuOpen(true)}
    >
      <HamburgerMenuIcon className="w-full h-full" />
    </div>
  );
};

export const CrossIcon = ({
  setMobileMenuOpen,
  classNames,
}: SetMobileMenuOpenProps) => {
  return (
    <div
      className={cn(
        "w-6 sm:h-6 md:w-7 md:h-7 ",
        "cursor-pointer flex justify-center items-center",
        classNames
      )}
      onClick={() => setMobileMenuOpen(false)}
    >
      <Cross1Icon className="w-full h-full" />
    </div>
  );
};
