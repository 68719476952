import { Input } from "@components/ui/input";
import { BaseFormInputProps } from "@interfaces/general";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/components/ui/form";

import CustomTooltip from "@components/Tooltip/CustomTooltip";
import { cn } from "@lib/utils";

interface CustomInputProps extends BaseFormInputProps {
  className?: string;
  placeholder?: string;
  showError?: boolean;
}

export default function CustomInput({
  field,
  label,
  isEditable = true,
  isDisabled = false,
  tooltip,
  desc,
  className,
  placeholder,
  showError = true,
}: CustomInputProps) {
  const disabled = isDisabled ? isDisabled : !isEditable || false;

  return (
    <FormItem>
      <div className="flex items-center">
        {label && <FormLabel>{label}</FormLabel>}

        {tooltip && <CustomTooltip>{tooltip} </CustomTooltip>}
      </div>

      <Input
        type="text"
        {...field}
        placeholder={placeholder}
        disabled={disabled}
        className={cn(
          "border-2 border-gray-200 focus:border-gray-300 rounded-none",
          className
        )}
      />

      {desc && <FormDescription>{desc}</FormDescription>}

      {showError && <FormMessage />}
    </FormItem>
  );
}
