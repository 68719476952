"use client";

import Logo from "@styles/Logo";

import { HamburgerIcon, CrossIcon } from "./icons";

import SignInButtonGroup from "@components/Public/SignIn/SignInButtonGroup";
import { useState, useEffect } from "react";
import { cn } from "@lib/utils";
import PublicNavMenu from "./PublicNavMenu";

export default function PublicHeader({
  mobileMenuOpen,
  setMobileMenuOpen,
}: {
  mobileMenuOpen?: boolean;
  setMobileMenuOpen: (open: boolean) => void;
  className?: string;
}) {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > window.innerHeight;
      setIsScrolled(scrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className=" ">
      <header
        className={cn(
          "px-6 sm:px-6 w-full fixed z-50 top-0 flex justify-center items-center h-16 bg-mainDark",
          isScrolled ? "shadow-xl " : " "
        )}
      >
        <nav className="py-3 w-full">
          <div className="flex items-center justify-between mx-auto">
            <div className="flex items-center ">
              <Logo href="/" />
              <PublicNavMenu />
            </div>

            <div className="flex items-center">
              <div className="md:hidden text-white ml-6">
                {mobileMenuOpen ? (
                  <CrossIcon setMobileMenuOpen={setMobileMenuOpen} />
                ) : (
                  <HamburgerIcon setMobileMenuOpen={setMobileMenuOpen} />
                )}
              </div>
            </div>
            <div className="hidden md:flex ">
              <SignInButtonGroup />
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}
