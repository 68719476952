export const WEBSITE_NAME = "BusinessForecast.com";
export const WEBSITE_NAME_COM = `${WEBSITE_NAME}.com`;
export const WEBSITE_EMAIL = "info@businessforecast.com";

export const BUSINESS_NAME = `${WEBSITE_NAME}`;
export const BUSINESS_ADDRESS = "Generaal Spoorlaan 197";
export const BUSINESS_POSTAL_CODE = "2283GG";
export const BUSINES_PHONE = "+31 6 41 200 305";

export const optionsYesNo = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const genericErrorToast = {
  title: "An error occurred",
  description:
    "Unable to process your request at this time. Please try again later.",
};
