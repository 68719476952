import React from "react";
import SignInButton from "./SignInButton";
import { useUserInput } from "@providers/UserInputProvider";
import Link from "next/link";
import { Button } from "@components/ui/button";
import Spinner from "@components/Spinner/Spinner";
import { useAuth } from "@hooks/useAuth";
// import { ProfileDropdown } from "./ProfileDropdown";
import { ProfileMenu } from "@components/Header/ProfileMenu";

const SignInButtonGroup = ({ columnLayout = false }) => {
  const { userDataIsLoading } = useUserInput() as any;
  const { isAuthenticated } = useAuth();

  if (userDataIsLoading) {
    return (
      <div className="py-2 flex justify-center">
        <Spinner />
      </div>
    );
  }

  if (isAuthenticated) {
    return (
      <div
        className={`flex items-center gap-x-3 ${
          columnLayout ? "flex-col gap-y-3 w-full" : "lg:flex-1 "
        }`}
      >
        {/* <Link href={`/loading`} className={`${columnLayout ? "w-full" : ""}`}>
          <Button size="sm" className={`${columnLayout ? "w-full" : ""}`}>
            Dashboard
          </Button>
        </Link> */}

        <ProfileMenu />
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div
        className={`flex ${
          columnLayout ? "flex-col gap-y-4" : "items-center gap-x-8"
        }`}
      >
        <div
          className={`${
            columnLayout
              ? "flex flex-col items-center gap-y-3 w-full"
              : "lg:flex lg:flex-1 lg:justify-end gap-x-3 items-center"
          }`}
        >
          <SignInButton
            variant="link"
            label="Sign in"
            size="default"
            className={` ${
              columnLayout
                ? "w-full bg-gray-300 hover:text-black  "
                : "text-white"
            }`}
          />
          <SignInButton
            label="Sign up"
            size="default"
            className={`${columnLayout ? "w-full" : ""}`}
          />
        </div>
      </div>
    );
  }
};

export default SignInButtonGroup;
