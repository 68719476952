import React from "react";
import Link from "next/link";
import icon from "@public/images/logo/icon.png";
import Image from "next/image";

const PublicFooterTop = () => {
  return (
    <div>
      <div className="mx-auto flex items-center justify-center mb-4 xl:justify-start ">
        <Link href="/">
          <Image src={icon} alt="Icon" width={50} height={50} />
        </Link>
      </div>
      <div className="   ">
        <p className="text-white font-bold text-xl text-center xl:text-left  ">
          Forecast and valuation platform
        </p>
      </div>
    </div>
  );
};

export default PublicFooterTop;
