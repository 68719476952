"use client";
import { useRef, useEffect } from "react";
import { cn } from "@lib/utils";
import Link from "next/link";
import { useAuth } from "@hooks/useAuth";

import PublicMobileMenuItems from "./PublicMobileMenuItems";
import { Button } from "@components/ui/button";
import SignInButton from "@components/Public/SignIn/SignInButton";
import SignInButtonGroup from "@components/Public/SignIn/SignInButtonGroup";

const PublicMobileMenu = ({ mobileMenuOpen, setMobileMenuOpen }: any) => {
  const sidebarRef = useRef<HTMLDivElement>(null);

  const { isAuthenticated } = useAuth();

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 640px)");

    function handleResize(e: any) {
      if (e.matches) {
        setMobileMenuOpen(false);
      }
    }

    handleResize(mediaQuery);

    mediaQuery.addEventListener("change", handleResize);

    function handleClickOutside(event: MouseEvent) {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        setMobileMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, [setMobileMenuOpen]);

  return (
    <div
      ref={sidebarRef}
      className={cn(
        `fixed top-[60px] w-full h-full z-50 bg-gray-200 shadow-md transition-left duration-300 `,
        mobileMenuOpen ? "left-0" : "-left-full"
      )}
    >
      {mobileMenuOpen && (
        <nav className="flex flex-col h-full w-full px-5 py-4 border-r border-gray-200 ">
          <PublicMobileMenuItems setMobileMenuOpen={setMobileMenuOpen} />
          <div className="border-b-2 border-gray-300 mt-10 mb-4"></div>
          <SignInButton
            size="lg"
            label={isAuthenticated ? "Dashboard" : "Get started"}
          />
        </nav>
      )}
    </div>
  );
};

export default PublicMobileMenu;
