import React from "react";
import { publicNavigationFooter } from "@constants/navigation/navigation";

import PublicFooterMenuSegment from "./PublicFooterMenuSegment";
import { platform } from "@constants/staticPages/platform";

const PublicFooterMenu = () => {
  return (
    <div className="flex flex-col sm:flex-row gap-8 sm:gap-24 mx-auto justify-center xl:items-start text-center sm:text-left">
      <PublicFooterMenuSegment
        label="Platform"
        subitems={Object.values(platform).map((item: any) => ({
          label: item.title,
          href: item.url,
        }))}
      />

      {/* <PublicFooterMenuSegment
        label="Use cases"
        subitems={useCases.map((item: any) => ({
          label: item.title,
          href: item.url,
        }))}
      /> */}
      {publicNavigationFooter.map((item: any) => (
        <PublicFooterMenuSegment
          key={item.label}
          label={item.label}
          subitems={item.subitems}
        />
      ))}
    </div>
  );
};

export default PublicFooterMenu;
