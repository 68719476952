import React from "react";
import { WEBSITE_NAME } from "@constants/general";
import Link from "next/link";
`

`;
const PublicFooterBottom = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className=" text-sm text-gray-400 text-center">
      <span>
        © {currentYear} {WEBSITE_NAME}.
      </span>
      <span> All rights reserved.</span>

      <span>
        {" "}
        <Link
          href="/policies/privacy-policy"
          className=" underline hover:text-main"
        >
          Privacy & Legal
        </Link>
      </span>
    </div>
  );
};

export default PublicFooterBottom;
