import React from "react";
import Spinner from "@components/Spinner/Spinner";
import { Button } from "@components/ui/button";
import { ButtonProps } from "@components/ui/button";

interface CustomButtonProps extends ButtonProps {
  isLoading?: boolean;
  disabled?: boolean;
  buttonText?: string;
  children?: React.ReactNode;
}

const CustomButton = React.forwardRef<HTMLButtonElement, CustomButtonProps>(
  (
    {
      isLoading,
      onClick,
      children,
      buttonText,
      size = "default",
      type = "submit",
      variant,
      disabled,
    }: CustomButtonProps,
    ref
  ) => {
    return (
      <Button
        ref={ref}
        type={type}
        onClick={onClick}
        disabled={disabled || isLoading}
        variant={variant}
        size={size}
      >
        <div className="flex items-center gap-x-1">
          {isLoading && <Spinner className="w-4 h-4" />}
          {children || <div>{buttonText}</div>}
        </div>
      </Button>
    );
  }
);

CustomButton.displayName = "CustomButton";

export default CustomButton;
