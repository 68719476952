import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { PropsWithChildren } from "react";

const CustomTooltip = ({ children }: PropsWithChildren) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="ml-2 bg-gray-400 rounded-full h-4 w-4 flex items-center justify-center cursor-default ">
            <span className="text-white text-xs font-normal ">i</span>
          </div>
        </TooltipTrigger>
        <TooltipContent className="  max-w-52  text-center p-2 ">
          <p className="text-xxs leading-snug ">{children}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default CustomTooltip;
