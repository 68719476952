// components/PublicFooterWrapper.tsx
import React from "react";

const PublicFooterWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <footer className="px-6 py-10  bg-gray-800 text-white ">
      <div className="max-w-7xl w-full mx-auto">
        <div className=" ">{children}</div>
      </div>
    </footer>
  );
};

export default PublicFooterWrapper;
