"use client";

import * as React from "react";
import Link from "next/link";
import { cn } from "@/lib/utils";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";

import SignInButtonGroup from "@components/Public/SignIn/SignInButtonGroup";
import ListItem from "./ListItem";
import { useUserInput } from "@providers/UserInputProvider";

import { publicNavigation } from "@constants/navigation/navigation";

export default function PublicNavMenu() {
  const { subscriptionData } = useUserInput() as any;

  const activeSubscription = subscriptionData?.status === "active";

  // const publicNavigationWithoutPricing: any = [
  //   {
  //     label: "Platform",
  //     subitems: publicNavigation,
  //   },
  //   {
  //     label: "Consulting",
  //     href: "/",
  //   },
  //   ...(activeSubscription
  //     ? []
  //     : [
  //         {
  //           label: "Pricing",
  //           href: "/pricing",
  //         },
  //       ]),
  //   // {
  //   //   label: "Book a demo",
  //   //   href: "/book-a-demo",
  //   // },
  // ];

  return (
    <>
      <NavigationMenu className="ml-8 hidden md:flex md:gap-x-0  ">
        <NavigationMenuList>
          {publicNavigation.map((item: any) => {
            if (item.subitems) {
              return (
                <NavigationMenuItem key={item.label}>
                  <NavigationMenuTrigger>{item.label}</NavigationMenuTrigger>
                  <NavigationMenuContent>
                    <ul className="  p-4 w-[400px] ">
                      {item.subitems.map((subitem: any) => (
                        <ListItem
                          key={subitem.label}
                          label={subitem.label}
                          tagline={subitem.tagline}
                          href={subitem.href}
                          icon={subitem.icon}
                        />
                      ))}
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              );
            } else {
              return (
                <NavigationMenuItem key={item.label}>
                  <Link href={item.href} legacyBehavior passHref>
                    <NavigationMenuLink
                      className={navigationMenuTriggerStyle()}
                    >
                      {item.icon && (
                        <item.icon className="h-4 w-4 text-muted-foreground mr-2" />
                      )}
                      {item.label}
                    </NavigationMenuLink>
                  </Link>
                </NavigationMenuItem>
              );
            }
          })}
        </NavigationMenuList>
      </NavigationMenu>
    </>
  );
}
