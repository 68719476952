import React from "react";
import Newsletter from "@app/(public)/(home)/NewsLetter/Newsletter";

const PublicFooterNewsletter = () => {
  return (
    <div className=" max-w-md mx-auto lg:mx-0   ">
      <Newsletter />
    </div>
  );
};

export default PublicFooterNewsletter;
